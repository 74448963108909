<template>
  <div>
    <div><h1>Templates</h1></div>
    <hr>
    <!--children: [
        { title: 'Activity Email Template', icon:'none' },
        { title: 'Client Update Template', icon:'none' },
        { title: 'Journal Email Template', icon:'none' },
        { title: 'Reopen Email Template', icon:'none' },
        { title: 'Global Email Template', icon:'none' }
      ]-->
    <div class="content-wrapper-block">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openActivityUpdateTemplate()"
      >
        Activity Update Template
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openClientEmailTemplate()"
      >
        Client Update Template
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openJournalEmailTemplate()"
      >
        Journal Email Template
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openDesktopEmailTemplate()"
      >
        Desktop Email Template
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openInternalJournalEmailTemplate()"
      >
        Internal Journal Email Template
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openJournalCollectionsTemplate()"
      >
        Journal Collections Template
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openJournalDailyUpdate()"
      >
        Journal Daily Update
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openJournalDesktopUpdateTemplate()"
      >
        Desktop Update
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openJournalFinalReport()"
      >
        Journal Final Report
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openOperationsManagerNotificationTemplate()"
      >
        Operations Manager Notification
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openReopenEmailTemplate()"
      >
        Reopen Email Reminder Template
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openLicenseReminderEmailTemplate()"
      >
        License Reminder Email Template
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openGlobalEMailTemplate()"
      >
        Global Email Template
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openDocReportTemplate()"
      >
        Doc Report Templates
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openPublicCaseNotificationTemplate()"
      >
        Public Case Notification Template
      </b-button>
    </div>
  </div>
</template>


<script>
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "vue-good-table/dist/vue-good-table.css";
import store from "@/store";



export default {
  components: {

    BButton,
    // eslint-disable-next-line vue/no-unused-components
  },
  directives: {
    Ripple,
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
  },
  methods: {
    openJournalEmailTemplate() {
      this.$router.push('/admin/template/journal-email-template')
    },
    openDesktopEmailTemplate() {
      this.$router.push('/admin/template/desktop-email-template')
    },
    openJournalDesktopUpdateTemplate() {
      this.$router.push('/admin/template/journal-desktop-update-email-template')
    },
    openInternalJournalEmailTemplate() {
      this.$router.push('/admin/template/internal-journal-email-template')
    },
    openJournalCollectionsTemplate() {
      this.$router.push('/admin/template/journal-collections-email-template')
    },
    openJournalDailyUpdate() {
      this.$router.push('/admin/template/journal-daily-update-email-template')
    },
    openJournalFinalReport() {
      this.$router.push('/admin/template/journal-final-report-email-template')
    },
    openOperationsManagerNotificationTemplate() {
      this.$router.push('/admin/template/operations-manager-notification')
    },
    openReopenEmailTemplate() {
      this.$router.push('/admin/template/reopen-email-template')
    },
    openLicenseReminderEmailTemplate() {
      this.$router.push('/admin/template/license-reminder-email-template')
    },
    openClientEmailTemplate() {
      this.$router.push('/admin/template/client-update-template')
    },
    openActivityUpdateTemplate() {
      this.$router.push('/admin/template/activity-update-template')
    },
    openGlobalEMailTemplate() {
      this.$router.push('/admin/template/global-email-template')
    },
    openDocReportTemplate() {
      this.$router.push('/admin/template/doc-report-template')
    },
    openPublicCaseNotificationTemplate() {
      this.$router.push('/admin/template/public-case-notification-template');
    }
  }
}
</script>

<style>
  .btn-link-box {
    border-color: #476dae !important;
    margin-top: 1rem;
    width: 160px;
  }
  .content-wrapper-block {
    display: flex;
    flex-flow: wrap;
  }
</style>
